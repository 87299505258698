import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';

function AppDetails() {
  const [app, setApp] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApp = async () => {
      const { data, error } = await supabase
        .from('apps')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching app:', error);
      } else {
        setApp(data);
      }
    };

    fetchApp();
  }, [id]);

  if (!app) return <div>Loading...</div>;

  return (
    <div className="app-overlay">
      <button onClick={() => navigate('/')}>Close</button>
      <h2>{app.name}</h2>
      <iframe src={app.live_url} title={app.name} className="full-app-preview" />
    </div>
  );
}

export default AppDetails;