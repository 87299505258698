import React, { useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import styles from './AppPreview.module.css';

function AppPreview({ artifact, isHighlighted, onVote, categories }) {
  const [votes, setVotes] = useState(artifact?.likes || 0);
  const [isUpdating, setIsUpdating] = useState(false);

  const backgroundStyle = artifact?.thumbnail_url ? {
    backgroundImage: `url(${artifact.thumbnail_url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  } : {};

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Unknown';
  };

  const handleVote = async (value) => {
    if (isUpdating) return; // Prevent multiple simultaneous votes
    setIsUpdating(true);

    const voteChange = value;

    try {
      // Optimistic update
      setVotes((prevVotes) => prevVotes + voteChange);

      const { data, error } = await supabase.rpc('update_artifact_votes', {
        artifact_id: artifact.id,
        vote_change: voteChange,
      });

      if (error) throw error;

      // Update with server response
      if (data && data[0] && typeof data[0].updated_likes === 'number') {
        setVotes(data[0].updated_likes);
      } else {
        console.error('Unexpected server response:', data);
        throw new Error('Invalid server response');
      }

      // Check if onVote is a function before calling it
      if (typeof onVote === 'function') {
        onVote(artifact.id, data[0].updated_likes);
      }
    } catch (error) {
      console.error('Error updating votes:', error);
      // Revert optimistic update on error
      setVotes((prevVotes) => prevVotes - voteChange);
    } finally {
      setIsUpdating(false);
    }
  };

  if (!artifact) {
    return null; // or return a placeholder/skeleton component
  }

  return (
    <div className={`${styles.appPreview} ${isHighlighted ? styles.highlighted : ''}`}>
      {isHighlighted && <span className={styles.highlightBadge}>Featured</span>}
      <a href={artifact.url} target="_blank" rel="noopener noreferrer" className={styles.appLink}>
        <div className={styles.thumbnailContainer}>
          <div 
            className={styles.thumbnailZoom} 
            style={{backgroundImage: `url(${artifact.thumbnail_url})`}}
          />
        </div>
        {/* <div className={styles.appPreviewInfo}></div> */}
          <h3 className={styles.appPreviewName} title={artifact.artifact_name}>
            {artifact.artifact_name && artifact.artifact_name.length > 30 
              ? artifact.artifact_name.substring(0, 30) + '...' 
              : artifact.artifact_name}
          </h3>
        {/* </div> */}
      </a>
      <div className={styles.cardFooter}>
        <span className={styles.categoryBadge}>{getCategoryName(artifact.category_id)}</span>
        <div className={styles.voteContainer}>
          <button
            className={styles.voteButton}
            onClick={() => handleVote(1)}
            aria-label="Upvote"
          >
            ▲
          </button>
          <span className={styles.voteCount}>{votes}</span>
          <button
            className={styles.voteButton}
            onClick={() => handleVote(-1)}
            aria-label="Downvote"
          >
            ▼
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppPreview;