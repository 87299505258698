import { useState, useEffect } from 'react'
import { fetchProfiles } from '../lib/supabaseClient'
import styles from './CreatorDirectory.module.css'

function CreatorDirectory() {
  const [creators, setCreators] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function loadCreators() {
      try {
        setLoading(true)
        const profiles = await fetchProfiles()
        setCreators(profiles)
      } catch (err) {
        console.error('Error loading creators:', err)
        setError('Failed to load creators. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    loadCreators()
  }, [])

  if (loading) return <div className={styles.loading}>Loading creators...</div>
  if (error) return <div className={styles.error}>{error}</div>

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Folks who are a DM away.</h2>
      {creators.length === 0 ? (
        <div className={styles.noData}>No creators found. Be the first to sign up!</div>
      ) : (
        <div className={styles.creatorGrid}>
          {creators.map((creator) => (
            <div key={creator.id} className={styles.creatorCard}>
              <img
                src={creator.avatar_url || '/default-avatar.png'}
                alt={`${creator.username}'s avatar`}
                className={styles.avatar}
              />
              <h3 className={styles.username}>{creator.username || 'Anonymous'}</h3>
              <p className={styles.bio}>{creator.bio || 'No bio available'}</p>
              <p className={styles.lastActive}>Last active {creator.last_active || 'recently'}</p>
              <button className={styles.superDMButton}>Send a SuperDM</button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CreatorDirectory
