import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './components/Home';
import AppDetails from './components/AppDetails';
import SubmissionLink from './components/SubmissionLink';
import CreatorDirectory from './components/CreatorDirectory';
import KnowledgeBase from './components/KnowledgeBase';
import './App.css';
import { Analytics } from "@vercel/analytics/react"

function Footer() {
  return (
    <div className="fixed bottom-0 right-0 p-4">
      <p className="text-center">&copy; {new Date().getFullYear()} JustBuildApps</p>
    </div>
  )
}

function Social() {
  return (
    <footer className="social-icons flex justify-between w-full py-4 mt-auto fixed bottom-0 px-4">
      <div className="flex space-x-4">
      <a href="https://twitter.com/JustBuildApps" target="_blank" rel="noopener noreferrer" aria-label="Twitter" className="text-white hover:text-gray-300 transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
          </svg>
        </a>
        {/* <a href="/creators" aria-label="Creator Directory" className="text-white hover:text-gray-300 transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
        </a> */}
        <a href="/" aria-label="App Gallery" className="text-white hover:text-gray-300 transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        </a>
        {/* <a href="/kb" aria-label="Knowledge Base" className="text-white hover:text-gray-300 transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M16 6l4 14"></path>
            <path d="M12 6v14"></path>
            <path d="M8 8v12"></path>
            <path d="M4 4v16"></path>
          </svg>
        </a> */}
      </div>
            
      <div className="flex space-x-4">
        {/* <a href="https://twitter.com/JustBuildApps" target="_blank" rel="noopener noreferrer" aria-label="Twitter" className="text-white hover:text-gray-300 transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
          </svg>
        </a> */}
        <a href="https://discord.gg/H4K2SEcNfF" target="_blank" rel="noopener noreferrer" aria-label="Discord" className="text-white hover:text-gray-300 transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"/>
          </svg>
        </a>
      </div>
    </footer>
  )
}

function App() {
  return (
    <Router>
      <div className="App flex flex-col items-center justify-between min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 text-white p-4 relative">
        <Helmet>
          <title>JustBuildApps - The TikTok of App Building</title>
          <meta name="description" content="Discover and explore a diverse array of web applications with JustBuildApps. An addictive, discovery-driven platform showcasing the vast possibilities of app development." />
          <meta property="og:title" content="JustBuildApps - The TikTok of App Building" />
          <meta property="og:description" content="Discover and explore a diverse array of web applications with JustBuildApps. An addictive, discovery-driven platform showcasing the vast possibilities of app development." />
          <meta property="og:image" content="https://yzckfvnaitivdqomczeg.supabase.co/storage/v1/object/public/thumbnails/Screenshot%202024-09-09%20at%202.58.31%20AM.png" />
          <meta property="og:url" content="https://justbuildapps.com" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/app/:id" element={<AppDetails />} />
          <Route path="/creators" element={<CreatorDirectory />} />
          <Route path="/kb/*" element={<KnowledgeBase />} />
        </Routes>
        <Footer />
        <Social />
      </div>
    </Router>
  )
}

export default App