import React from 'react';
import ReactDOM from 'react-dom/client';
import posthog from 'posthog-js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Initialize PostHog
posthog.init('phc_i6WR7pcKTMfCE1qKskuqJmV6UUHH60OsTgtSKSatesF', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only'
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals((metric) => {
  // Send web vitals to PostHog
  posthog.capture('web_vitals', metric);
});