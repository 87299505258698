import React from 'react';
import AppList from './AppList';

function Home() {
  return (
    <div className="home">
      <AppList />
    </div>
  );
}

export default Home;