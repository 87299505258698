import React, { useState } from 'react';
import styles from './SubmissionLink.module.css';

function SubmissionLink() {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleSubmitClick = () => {
    setShowOverlay(true);
    // Add any additional logic for showing the overlay or submission form
  };

  return (
    <button onClick={handleSubmitClick} className={styles.submissionLink}>
      Submit Your Artifact
    </button>
  );
}

export default SubmissionLink;
