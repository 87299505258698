import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './KnowledgeBase.module.css'

function FileItem({ item }) {
  return (
    <article className={styles.articleItem}>
      <Link to={`/kb/${item.path}`} className={styles.articleLink}>
        <h3 className={styles.articleTitle}>{item.name}</h3>
        <p className={styles.articleExcerpt}>{item.excerpt}</p>
        <div className={styles.articleMeta}>
          {item.type} • {item.readTime} min read
        </div>
      </Link>
    </article>
  )
}

function KnowledgeBase() {
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchKnowledgeBaseItems = async () => {
      try {
        // Simulating an API call with setTimeout
        setTimeout(() => {
          const mockItems = [
            {
              id: 1,
              name: 'Understanding How Apps Work',
              type: 'Article',
              path: 'how-apps-work',
              excerpt: 'Dive into the fundamentals of app architecture and functionality.',
              readTime: 7,
              category: 'Fundamentals'
            },
            {
              id: 2,
              name: 'AI-Powered App Development',
              type: 'Article',
              path: 'ai-app-development',
              excerpt: 'Explore how AI is revolutionizing the app creation process.',
              readTime: 10,
              category: 'AI & Development'
            },
            {
              id: 3,
              name: 'Cursor + Replit + Claude: A Powerful Combo',
              type: 'Tutorial',
              path: 'cursor-replit-claude',
              excerpt: 'Learn how to leverage these tools for efficient app development.',
              readTime: 15,
              category: 'Tools & Techniques'
            },
            {
              id: 4,
              name: 'Limitations and Complications in AI App Development',
              type: 'Article',
              path: 'ai-limitations',
              excerpt: 'Understand the current challenges and limitations in AI-assisted development.',
              readTime: 8,
              category: 'AI & Development'
            },
            {
              id: 5,
              name: 'Getting Started with JBA',
              type: 'Guide',
              path: 'getting-started',
              excerpt: 'Your first steps into the world of JustBuildApps.',
              readTime: 5,
              category: 'Fundamentals'
            },
            {
              id: 6,
              name: 'Become a Creator',
              type: 'Link',
              path: '/creator',
              excerpt: 'Learn how to become a JBA creator and share your apps with the world.',
              readTime: 3,
              category: 'Community'
            }
          ]
          setItems(mockItems)
          setIsLoading(false)
        }, 1000)
      } catch (err) {
        setError('Failed to fetch knowledge base items')
        setIsLoading(false)
      }
    }

    fetchKnowledgeBaseItems()
  }, [])

  if (isLoading) return <div className={styles.loadingMessage}>Loading knowledge base...</div>
  if (error) return <div className={styles.errorMessage}>{error}</div>

  const categories = [...new Set(items.map(item => item.category))]

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>Knowledge Base</h1>
        <p className={styles.subtitle}>Explore our guides and documentation</p>
      </header>
      <main className={styles.main}>
        {categories.map(category => (
          <section key={category} className={styles.category}>
            <h2 className={styles.categoryTitle}>{category}</h2>
            <div className={styles.articleGrid}>
              {items.filter(item => item.category === category).map(item => (
                <FileItem key={item.id} item={item} />
              ))}
            </div>
          </section>
        ))}
      </main>
    </div>
  )
}

export default KnowledgeBase
