import React, { useState, useEffect, useRef, useCallback } from 'react';
import { supabase } from '../lib/supabaseClient';
import AppPreview from './AppPreview';
import styles from './AppList.module.css'
import { debounce } from 'lodash';
import SubmissionLink from './SubmissionLink';

const ITEMS_PER_PAGE = 30;
const FETCH_TIMEOUT = 20000; // 20 seconds timeout

function AppList() {
  const [artifacts, setArtifacts] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [timeoutCounter, setTimeoutCounter] = useState(FETCH_TIMEOUT / 1000);
  const backgroundRef = useRef(null);
  const controlsRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [likesSortOrder, setLikesSortOrder] = useState(null); // New state for likes sorting
  const [dateSortOrder, setDateSortOrder] = useState('desc'); // New state for date sorting
  const [filterByName, setFilterByName] = useState('');
  const [filterByCategory, setFilterByCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [highlightedArtifacts, setHighlightedArtifacts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const removeEllipsis = (url) => {
    return url.endsWith('…') ? url.slice(0, -1) : url
  }

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setLikesSortOrder(null); // Reset likes sort when changing other sort options
    setPage(0);
    setArtifacts([]);
    fetchInitialData();
  };

  const handleLikesSort = () => {
    setLikesSortOrder(prevOrder => {
      if (prevOrder === null || prevOrder === 'desc') return 'asc';
      return 'desc';
    });
    setSortBy('likes');
    setDateSortOrder(null); // Reset date sort when sorting by likes
    setPage(0);
    setArtifacts([]);
    fetchInitialData();
  };

  const handleDateSort = () => {
    setDateSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    setSortBy('created_at');
    setLikesSortOrder(null); // Reset likes sort when sorting by date
    setPage(0);
    setArtifacts([]);
    fetchInitialData();
  };

  const handleSortOrderChange = () => {
    setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    setPage(0);
    setArtifacts([]);
    fetchInitialData();
  };

  const handleNameFilterChange = (event) => {
    setFilterByName(event.target.value);
    setPage(0);
    setArtifacts([]);
    debouncedFetchInitialData();
  };

  const clearFilters = () => {
    setSortBy('created_at');
    setSortOrder('desc');
    setFilterByName('');
    setFilterByCategory('');
    setPage(0);
    setArtifacts([]);
    setHasMore(true);
    fetchInitialData();
  };

  useEffect(() => {
    let timer;
    if (loading) {
      console.log('Loading state changed to true, starting timeout countdown');
      timer = setInterval(() => {
        setTimeoutCounter(prev => {
          console.log(`Timeout countdown: ${prev - 1} seconds remaining`);
          return prev - 1;
        });
      }, 1000);
    } else {
      console.log('Loading state changed to false, clearing timeout countdown');
      clearInterval(timer);
      setTimeoutCounter(FETCH_TIMEOUT / 1000);
    }

    return () => clearInterval(timer);
  }, [loading]);

  useEffect(() => {
    if (timeoutCounter === 0) {
      console.error('Fetch timeout reached');
      setLoading(false);
      setError('Fetch timeout reached. Please try again later.');
      setTimeoutCounter(FETCH_TIMEOUT / 1000);
    }
  }, [timeoutCounter]);

  useEffect(() => {
    const testSupabaseConnection = async () => {
      try {
        console.log('Testing Supabase connection...')
        const { data, error } = await supabase.from('claude_urls').select('count')
        if (error) throw error
        console.log('Supabase connection successful. Total rows:', data[0].count)
      } catch (error) {
        console.error('Supabase connection test failed:', error)
      }
    }

    testSupabaseConnection()
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase
        .from('categories')
        .select('id, name')
        .order('name');
      if (error) {
        console.error('Error fetching categories:', error);
      } else {
        setCategories(data);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    fetchHighlightedArtifacts();
    fetchInitialData();
  }, []);

  const fetchHighlightedArtifacts = async () => {
    try {
      const { data, error } = await supabase
        .from('claude_urls')
        .select('*')
        .eq('highlighted', true)
        .order('created_at', { ascending: false })
        .limit(4);

      if (error) throw error;

      setHighlightedArtifacts(data);
    } catch (error) {
      console.error('Error fetching highlighted artifacts:', error);
    }
  };

  const fetchInitialData = async () => {
    if (isFetching) return;
    setIsFetching(true);
    setLoading(true);

    try {
      console.log('Fetching initial data...');
      let query = supabase
        .from('claude_urls')
        .select('*, categories(name)', { count: 'exact' })
        .limit(ITEMS_PER_PAGE);

      if (filterByName) {
        query = query.ilike('artifact_name', `%${filterByName}%`);
      }

      if (filterByCategory) {
        query = query.eq('category_id', filterByCategory);
      }

      if (sortBy === 'likes') {
        query = query.order('likes', { ascending: likesSortOrder === 'asc' });
      } else if (sortBy === 'created_at') {
        query = query.order('created_at', { ascending: dateSortOrder === 'asc' });
      }

      const { data, error, count } = await query;

      if (error) throw error;

      const cleanedData = data.map(item => ({
        ...item,
        url: removeEllipsis(item.url),
        category_name: item.categories ? item.categories.name : 'Unknown'
      }));

      setArtifacts(cleanedData);
      setHasMore(count > ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

  const fetchArtifacts = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    setError(null);
    const from = page * ITEMS_PER_PAGE;
    const to = from + ITEMS_PER_PAGE - 1;

    try {
      let query = supabase
        .from('claude_urls')
        .select('*, categories(name)', { count: 'exact' })
        .range(from, to);

      if (filterByName) {
        query = query.ilike('artifact_name', `%${filterByName}%`);
      }

      if (filterByCategory) {
        query = query.eq('category_id', filterByCategory);
      }

      if (sortBy === 'likes') {
        query = query.order('likes', { ascending: likesSortOrder === 'asc' });
      } else if (sortBy === 'created_at') {
        query = query.order('created_at', { ascending: dateSortOrder === 'asc' });
      }

      const { data, error, count } = await query;

      if (error) throw error;

      const cleanedData = data.map(item => ({
        ...item,
        url: removeEllipsis(item.url),
        category_name: item.categories ? item.categories.name : 'Unknown'
      }));

      setArtifacts(prevArtifacts => [...prevArtifacts, ...cleanedData]);
      setPage(prevPage => prevPage + 1);
      setHasMore(count > (page + 1) * ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching artifacts:', error);
      setError(`Failed to load artifacts. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreArtifacts = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    setError(null);
    const from = page * ITEMS_PER_PAGE;
    const to = from + ITEMS_PER_PAGE - 1;

    await delay(400);

    try {
      const { data, error } = await supabase
        .from('claude_urls')
        .select('*, categories(name)')
        .range(from, to)
        .order(sortBy, { ascending: sortBy === 'likes' ? likesSortOrder === 'asc' : dateSortOrder === 'asc' });

      if (error) throw error;

      const cleanedData = data.map(item => ({
        ...item,
        url: removeEllipsis(item.url),
        category_name: item.categories ? item.categories.name : 'Unknown'
      }));

      setArtifacts(prevArtifacts => [...prevArtifacts, ...cleanedData]);
      setPage(prevPage => prevPage + 1);
      setHasMore(data.length === ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching artifacts:', error);
      setError(`Failed to load more artifacts. Please try again.`);
    } finally {
      setLoading(false);
      setTimeoutCounter(FETCH_TIMEOUT / 1000);
    }
  };

  const handleScroll = () => {
    if (controlsRef.current) {
      if (window.scrollY > 534) {
        controlsRef.current.classList.add(styles.fixed);
      } else {
        controlsRef.current.classList.remove(styles.fixed);
      }
    }

    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!loading && hasMore) {
        loadMoreArtifacts();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (backgroundRef.current) {
        const { clientX, clientY } = e
        const { width, height } = backgroundRef.current.getBoundingClientRect()
        const x = (clientX / width) * 100
        const y = (clientY / height) * 100
        backgroundRef.current.style.backgroundPosition = `${x}% ${y}%`
      }
    }

    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  const debouncedFetchInitialData = useCallback(
    debounce(() => {
      fetchInitialData();
    }, 300),
    [sortBy, sortOrder, filterByName, filterByCategory]
  );

  const handleVote = useCallback(async (artifactId, newVotes) => {
    // Update the votes for both highlighted and regular artifacts
    setHighlightedArtifacts(prev => 
      prev.map(art => art.id === artifactId ? { ...art, likes: newVotes } : art)
    );
    setArtifacts(prev => 
      prev.map(art => art.id === artifactId ? { ...art, likes: newVotes } : art)
    );
  }, []);

  const handleSubmitClick = () => {
    setShowOverlay(true);
  };

  const closeOverlay = useCallback(() => {
    setShowOverlay(false);
  }, []);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        closeOverlay();
      }
    };

    if (showOverlay) {
      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [showOverlay, closeOverlay]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className={styles.artifactListContainer}>
      <div ref={backgroundRef} className={styles.backgroundAnimation}></div>
      <h1 className={styles.title}>Claude Artifacts Gallery</h1>
      <p className={styles.description}>
        Explore a collection of amazing artifacts created by{' '}
        <a href="https://claude.ai/" target="_blank" rel="noopener noreferrer" className={styles.claudeLink}>
          Claude AI
          <svg className={styles.claudeLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path d="m19.6 66.5 19.7-11 .3-1-.3-.5h-1l-3.3-.2-11.2-.3L14 53l-9.5-.5-2.4-.5L0 49l.2-1.5 2-1.3 2.9.2 6.3.5 9.5.6 6.9.4L38 49.1h1.6l.2-.7-.5-.4-.4-.4L29 41l-10.6-7-5.6-4.1-3-2-1.5-2-.6-4.2 2.7-3 3.7.3.9.2 3.7 2.9 8 6.1L37 36l1.5 1.2.6-.4.1-.3-.7-1.1L33 25l-6-10.4-2.7-4.3-.7-2.6c-.3-1-.4-2-.4-3l3-4.2L28 0l4.2.6L33.8 2l2.6 6 4.1 9.3L47 29.9l2 3.8 1 3.4.3 1h.7v-.5l.5-7.2 1-8.7 1-11.2.3-3.2 1.6-3.8 3-2L61 2.6l2 2.9-.3 1.8-1.1 7.7L59 27.1l-1.5 8.2h.9l1-1.1 4.1-5.4 6.9-8.6 3-3.5L77 13l2.3-1.8h4.3l3.1 4.7-1.4 4.9-4.4 5.6-3.7 4.7-5.3 7.1-3.2 5.7.3.4h.7l12-2.6 6.4-1.1 7.6-1.3 3.5 1.6.4 1.6-1.4 3.4-8.2 2-9.6 2-14.3 3.3-.2.1.2.3 6.4.6 2.8.2h6.8l12.6 1 3.3 2 1.9 2.7-.3 2-5.1 2.6-6.8-1.6-16-3.8-5.4-1.3h-.8v.4l4.6 4.5 8.3 7.5L89 80.1l.5 2.4-1.3 2-1.4-.2-9.2-7-3.6-3-8-6.8h-.5v.7l1.8 2.7 9.8 14.7.5 4.5-.7 1.4-2.6 1-2.7-.6-5.8-8-6-9-4.7-8.2-.5.4-2.9 30.2-1.3 1.5-3 1.2-2.5-2-1.4-3 1.4-6.2 1.6-8 1.3-6.4 1.2-7.9.7-2.6v-.2H49L43 72l-9 12.3-7.2 7.6-1.7.7-3-1.5.3-2.8L24 86l10-12.8 6-7.9 4-4.6-.1-.5h-.3L17.2 77.4l-4.7.6-2-2 .2-3 1-1 8-5.5Z" />
          </svg>
        </a>
      </p>

      <SubmissionLink onClick={handleSubmitClick} />
      <h2 className={styles.weeklyHighlights}>Featured </h2>
      <div className={styles.highlightSection}>
        <div className={styles.highlightScroll}>
          {highlightedArtifacts.map((app) => {
            const cleanedUrl = removeEllipsis(app.url) // Clean the URL
            return (
              <a 
                key={app.id} 
                href={cleanedUrl} 
                target="_blank" 
                rel="noopener noreferrer" 
                className={styles.highlightCard}
                onClick={() => console.log(`Artifact URL: ${cleanedUrl}`)} // Log the cleaned URL on click
              >
                <img src={app.thumbnail_url} alt={app.artifact_name} className={styles.highlightImage} />
                <div className={styles.highlightInfo}>
                  <h3>{app.artifact_name}</h3>
                  <p className={styles.highlightDescription}>{app.description}</p>
                  <div className={styles.highlightMeta}>
                    <span className={styles.voteCount}>
                      <svg viewBox="0 0 24 24" width="16" height="16">
                        <path fill="currentColor" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                      </svg>
                      {app.likes}
                    </span>
                    <span className={styles.categoryBadge}>
                      {categories.find(cat => cat.id === app.category_id)?.name || 'Unknown'}
                    </span>
                    
                  </div>
                </div>
              </a>
            )
          })}
        </div>
      </div>

      <div ref={controlsRef} className={styles.controls}>
        <input
          type="text"
          placeholder="Filter by Name"
          value={filterByName}
          onChange={handleNameFilterChange}
          className={styles.nameFilter}
        />
        <button 
          className={styles.iconButton} 
          onClick={handleDateSort} 
          title="Sort by Date"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"/>
          </svg>
          <svg className={`${styles.sortArrow} ${dateSortOrder === 'asc' ? styles.up : ''}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10l5 5 5-5z"/>
          </svg>
        </button>
        <button 
          className={styles.iconButton} 
          onClick={handleLikesSort} 
          title="Sort by Likes"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
          </svg>
          <svg className={`${styles.sortArrow} ${likesSortOrder === 'asc' ? styles.up : ''}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10l5 5 5-5z"/>
          </svg>
        </button>
        <button 
          onClick={clearFilters} 
          className={`${styles.iconButton} ${styles.tooltipContainer}`}
          title="Clear All Filters"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={styles.clearIcon}>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          </svg>
        </button>
      </div>

      <div className={styles.artifactList}>
        {artifacts.map(artifact => (
          artifact && (
            <AppPreview 
              key={`${artifact.id}-${artifact.url}`} 
              artifact={artifact} 
              categories={categories}
              onVote={handleVote}
            />
          )
        ))}
      </div>
      {loading && <p className={styles.loadingMessage}>Loading more artifacts...</p>}
      {!hasMore && <p className={styles.noMoreMessage}>No more Claude artifacts to load</p>}
      {error && (
        <div className={styles.errorMessage}>
          <p>{error}</p>
          <button onClick={loadMoreArtifacts}>Try Again</button>
        </div>
      )}

      {showOverlay && (
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <button className={styles.closeButton} onClick={closeOverlay}>
              &times;
            </button>
            <iframe
              src="https://app.youform.com/forms/cxmpdrmw"
              loading="lazy"
              width="100%"
              height="700"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Submit Artifacts Form"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default AppList;