import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Supabase URL and Anon Key must be set in environment variables')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export async function fetchProfiles() {
  const { data, error } = await supabase
    .from('profiles')
    .select('*')
  
  if (error) throw error
  return data
}

console.log('Initializing Supabase client with:', { 
  supabaseUrl, 
  supabaseAnonKey: supabaseAnonKey.slice(0, 5) + '...' 
})

// Test the connection
supabase
  .from('claude_urls')
  .select('id')
  .limit(1)
  .then(({ data, error }) => {
    if (error) {
      console.error('Error testing Supabase connection:', error)
    } else {
      console.log('Supabase connection test successful:', data)
    }
  })
  .catch(err => {
    console.error('Unexpected error during Supabase connection test:', err)
  })

